<template>
	<div>
		<Navbar/>
		<div :style="{'background-image': `url(${require('../assets/img/cover8.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}" class="pt-12">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8" >
				<div class="lg:text-center mb-8">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> E-MAILS </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('mails.headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
						{{ $t('mails.headline2') }}
					</p>
				</div>
			</div>
		</div>
		<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
			<!-- component -->
			<section>
				<div class="container max-w-full mx-auto py-4 px-6">
					<div class="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
						<div class="relative block flex flex-col md:flex-row items-center">
							<div class="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-mr-4">
								<div class="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
									<div class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
										<h1	class="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
											Mails One
										</h1>
										<h2 class="text-sm text-gray-500 text-center pb-6">1.99€/{{ $t('home.month') }}</h2>
										{{ $t('mails.offer1_desc') }}
									</div>
									<div class="flex flex-wrap mt-3 px-6">
										<ul>
											<li class="flex items-center">
												<div class=" rounded-full p-2 fill-current text-green-700">
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3">5 {{ $t('web.mail_account') }} </span
												>
											</li>
											<li class="flex items-center">
												<div class=" rounded-full p-2 fill-current text-green-700">
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3">1 {{ $t('web.domain') }}</span
												>
											</li>
											<li class="flex items-center">
												<div
														class=" rounded-full p-2 fill-current text-green-700"
												>
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3"
												>10 Go {{ $t('mails.of_storage') }}</span
												>
											</li>
										</ul>
									</div>
									<div class="block flex items-center p-8  uppercase">
										<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=26"
											class="mt-3 text-lg font-semibold
											bg-black w-full text-white rounded-lg
											px-6 py-3 block shadow-xl hover:bg-gray-700"
										>
											{{ $t('home.purchase') }}
										</a>
									</div>
								</div>
							</div>
							<div
									class="w-full max-w-md sm:w-2/3 lg:w-1/3 sm:my-5 my-8 relative z-10 bg-white rounded-lg shadow-lg"
							>
								<div
										class="text-sm leading-none rounded-t-lg bg-gray-200 text-black font-semibold uppercase py-4 text-center tracking-wide"
								>
									{{ $t('web.popular') }}
								</div>
								<div
										class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6"
								>
									<h1
											class="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide"
									>
										Mails Starter
									</h1>
									<h2 class="text-sm text-gray-500 text-center pb-6"><span class="text-3xl">3.99€</span> /{{ $t('home.month') }}</h2>
									{{ $t('mails.offer2_desc') }}
								</div>
								<div class="flex pl-12 justify-start sm:justify-start mt-3">
									<ul>
										<li class="flex items-center">
											<div class=" rounded-full p-2 fill-current text-green-700">
												<svg
														class="w-6 h-6 align-middle"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
												>
													<path
															d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
													></path>
													<polyline
															points="22 4 12 14.01 9 11.01"
													></polyline>
												</svg>
											</div>
											<span class="text-gray-700 text-lg ml-3">50 {{ $t('web.mail_account') }} </span>
										</li>
										<li class="flex items-center">
											<div
													class="rounded-full p-2 fill-current text-green-700"
											>
												<svg
														class="w-6 h-6 align-middle"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
												>
													<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
													<polyline points="22 4 12 14.01 9 11.01"></polyline>
												</svg>
											</div>
											<span class="text-gray-700 text-lg ml-3">1 {{ $t('web.domain') }}</span>
										</li>
										<li class="flex items-center">
											<div
													class=" rounded-full p-2 fill-current text-green-700"
											>
												<svg
														class="w-6 h-6 align-middle"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
												>
													<path
															d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
													></path>
													<polyline
															points="22 4 12 14.01 9 11.01"
													></polyline>
												</svg>
											</div>
											<span class="text-gray-700 text-lg ml-3"
											>50Go {{ $t('mails.of_storage') }}</span
											>
										</li>
									</ul>
								</div>

								<div class="block flex items-center p-8  uppercase">
									<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=27"
										class="mt-3 text-lg font-semibold
												bg-black w-full text-white rounded-lg
												px-6 py-3 block shadow-xl hover:bg-gray-700"
									>
										{{ $t('home.purchase') }}
									</a>
								</div>
							</div>
							<div
									class="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-ml-4"
							>
								<div
										class="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden"
								>
									<div
											class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6"
									>
										<h1
												class="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide"
										>
											Mails Pro
										</h1>
										<h2 class="text-sm text-gray-500 text-center pb-6">7.99€ /{{ $t('home.month') }}</h2>

										{{ $t('mails.offer3_desc') }}
									</div>
									<div class="flex flex-wrap mt-3 px-6">
										<ul>
											<li class="flex items-center">
												<div class=" rounded-full p-2 fill-current text-green-700">
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3">{{ $t('mails.domains') }} ∞ </span
												>
											</li>
											<li class="flex items-center">
												<div
														class=" rounded-full p-2 fill-current text-green-700"
												>
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3"
												>{{ $t('web.mail_account') }}  ∞ </span
												>
											</li>
											<li class="flex items-center">
												<div
														class=" rounded-full p-2 fill-current text-green-700"
												>
													<svg
															class="w-6 h-6 align-middle"
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															stroke-width="2"
															stroke-linecap="round"
															stroke-linejoin="round"
													>
														<path
																d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
														></path>
														<polyline
																points="22 4 12 14.01 9 11.01"
														></polyline>
													</svg>
												</div>
												<span class="text-gray-700 text-lg ml-3"
												>{{ $t('vps.storage') }}  ∞ </span
												>
											</li>
										</ul>
									</div>

									<div class="block flex items-center p-8  uppercase">
										<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=28"
											class="mt-3 text-lg font-semibold
													bg-black w-full text-white rounded-lg
													px-6 py-3 block shadow-xl hover:bg-gray-700"
										>
											{{ $t('home.purchase') }}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="mx-5">
			<ul class="md:grid md:grid-cols-3 md:col-gap-8 md:row-gap-10">
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/mailseasy.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('mails.oneclick') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('mails.oneclick_desc') }}</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/mailsmart.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('mails.simple') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('mails.simple_desc') }}
						</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/mailsecure.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('mails.virus') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('mails.virus_desc') }}
						</p>
					</div>
				</li>
			</ul>
			<p class="ml-20 text-sm text-gray-600 mt-8">{{ $t('mails.disclaimer') }}</p>
		</div>
		<div class="bg-gray-50 mt-12">
			<Footer />
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Navbar";
	import Footer from "@/components/Footer";
	export default {
		name: 'VPN',
      metaInfo: () => ({
        title: 'HydroSaaS',
        titleTemplate: 'VPN %s | Internet en toute sécurité',
        meta: [
          { name: 'description', content: 'Profitez d\'Internet en toute sécurité et choisissez l\'origine de votre VPN parmi une longue liste de pays disponibles. Surfez sans limites !' },
          { name: 'og:title', content: 'Internet en toute sécurité avec le VPN HydroSaaS' },
          { name: 'twitter:title', content: 'Internet en sécurité avec le VPN HydroSaaS' },
          { name: 'og:description', content: 'Profitez d\'Internet en toute sécurité et choisissez l\'origine de votre VPN parmi une longue liste de pays disponibles. Surfez sans limites !' },
          { name: 'twitter:description', content: 'Profitez d\'Internet en toute sécurité et choisissez l\'origine de votre VPN parmi une longue liste de pays disponibles. Surfez sans limites !' },
          { name: 'twitter:image', content: 'https://hydrosaas.com/vpn.png' },
          { name: 'og:image', content: 'https://hydrosaas.com/vpn.png' },
        ],
      }),
      htmlAttrs: {
        amp: true
      },
		components: {
			Footer,
			Navbar,
		},
	}
</script>
